.ProfessionalExperiencesListComponent {
    background-color: rgb(238, 238, 238);
    width: 100%;
    padding: 100px 0;
}

.ProfessionalExperiencesTopic {
    font-family: futura-pt, sans-serif;
    font-weight: 700;
    font-style: bold;
    font-size: 50px;
}