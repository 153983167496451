.Toolbar {
    height: 56px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    z-index: 90;
}

.Toolbar nav {
    height: 100%;
}

.NavigationItems {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: column;
    align-items: center;
    height: 100%;
}

.NavigationItem {
    padding: 30px;
    color: white;
}

.NavigationItem:hover {
    color: rgb(175, 41, 41);
}

@media (min-width: 500px) {
    .NavigationItems {
        flex-flow: row;
    }
}

@media (max-width: 499px) {
    .DesktopView {
        display: none;
    }
}