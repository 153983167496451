.WorkImage {
    width: 300px;
    height: 250px;
    line-height: 250px;
    text-align: center;
    border: 1px solid #eee;
    border-radius: 25px;
    box-shadow: 0 2px 3px rgb(221, 221, 221);
    margin: 20px 20px;
    box-sizing: border-box;
    cursor: pointer;
    background-color: rgb(255, 255, 255);
    color: white;
}

.ImageInside {
    width: 100%;
    height: 100%;
    line-height: 100%;
    object-fit: contain;
}

.DetailInformation {
    text-align: left;
    font-family: futura-pt, sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 20px;
    padding:20px;
}