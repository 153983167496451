.AboutComponent {
    padding-bottom: 100px;
}

.AboutTopic {
    font-family: futura-pt, sans-serif;
    font-weight: 700;
    font-style: bold;
    font-size: 50px;
}
.AboutInfo {
    font-family: futura-pt, sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 30px;
    padding: 20px;
}

.ProfileImage {
    height: 300px;
    width: auto;
}