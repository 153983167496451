.MainBanner {
    height: 100%;
    width: 100%;
    min-height:100%;
    box-sizing: border-box;
    background-image: url('../../assets/banner.jpg');
    /* background-image: linear-gradient( rgb(14, 14, 14) , rgb(36, 36, 36)); */
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: "center";
    clip-path: polygon(0 0, 100% 0, 100% 90%, 53% 90%, 50% 95%, 47% 90%, 0% 90%);
}

/* margin vs padding: use margin instead to allow full icon to be clickable within the border */
.SocialIcons {
    margin: 10px;
    width: 50px;
    height: auto;
}

.SocialIcons:hover,
.SocialIcons:active {
	animation: bounce 1s;
}

.Name {
    margin-bottom: 20px;
    padding-top: 20px;
    font-family: "Century Gothic", 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 80px;
    color: #FFFFFF;
    letter-spacing: 10px;
    border-bottom: 1px solid white;
}

.PersonalTag {
    font-family: "Century Gothic", 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: #FFFFFF;
    letter-spacing: 7px;
    font-size: 20px;
}

@media (max-width: 499px) {
    .MainBanner {
        clip-path: polygon(0 0, 100% 0, 100% 90%, 60% 90%, 50% 95%, 40% 90%, 0% 90%);
    }
    .Name {
        font-size: 40px;
    }
    .PersonalTag {
        font-size: 20px;
        letter-spacing: 2px;
    }
    .SocialIcons {
        width: 40px;
        height: auto;
        margin: 5px;
    }
}

/* Icons will bounce when hovered
   TODO: allow the icon to bounce even off hover. Chain effect if hovered left to right */
@keyframes bounce {
	0%, 20%, 60%, 100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	40% {
		-webkit-transform: translateY(-20px);
		transform: translateY(-20px);
	}
	80% {
		-webkit-transform: translateY(-10px);
		transform: translateY(-10px);
	}
}