.Logo {
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 50px;
    width: 300px;
    height: auto;
}

@media (max-width: 499px) {
    .Logo {
        width: 200px;
        padding-bottom: 10px;
    }
}